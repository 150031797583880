import React, { useState, useEffect, useMemo } from "react";
import loadable from "@loadable/component";
import { Link,useNavigate  } from "react-router-dom";
import renderHTML from "react-render-html";
import { useSelector, useDispatch } from "react-redux";
import PreFooter from "../components/PreFooter";
import { loadHomePageCMS } from "../store/api/homePage";
import { getHomeFreeVidSec } from "../store/api/homeFreeVidSec";
import { Helmet } from "react-helmet";
import { setSubscriptionModal } from "../store/ui/subscriptionModal";
import { setLoginModalVisibility } from "../store/ui/loginModal";
import storage from "../services/storage";
// import { Link, useNavigate } from "react-router-dom";

const HomeSlider = loadable(() => import("../components/HomeSlider"), {
  ssr: false,
});
const PopularCourse = loadable(() => import("../components/PopularCourses"), {
  ssr: false,
});
const ResultSlider = loadable(() => import("../components/ResultSlider"), {
  ssr: false,
});
const OcCommunity = loadable(() => import("../components/OcCommunity"), {
  ssr: false,
});
const UnbeatablePrice = loadable(() => import("./UnbeatablePrice"), {
  ssr: false,
});
const WhatsNew = loadable(() => import("../components/WhatsNew"), {
  ssr: false,
});
const HomeDetails = loadable(() => import("../components/HomeDetails"), {
  ssr: false,
});
const HomeSlider3 = loadable(() => import("../components/HomeSlider3"), {
  ssr: false,
});
const HappyBtn = loadable(() => import("../components/HappyBtn"), {
  ssr: false,
});
const StudentRating = loadable(() => import("../components/StudentRating"), {
  ssr: false,
});

const UniSwiper = loadable(() => import("../components/UniSwiper"), {
  ssr: false,
});
const Teachers = loadable(() => import("../components/Teachers"), {
  ssr: false,
});
const FreeTrail = loadable(() => import("../components/FreeTrail"), {
  ssr: false,
});
const NewTeachers = loadable(() => import("../components/NewTeacher"), {
  ssr: false,
});
const QuickCarousal = loadable(() => import("../components/QuickCarousal"), {
  ssr: false,
});

const Students = loadable(() => import("../components/Students"), {
  ssr: false,
});
const HomeCourses = loadable(() => import("../components/HomeCourses"), {
  ssr: false,
});
const DragableButton = loadable(() => import("../components/DragableButton"), {
  ssr: false,
});

const Home = (props) => {
  const dispatch = useDispatch();
  // const userCount = useSelector((state) => state.ui.homePage.data.userCount);

  const homePageCMS = useSelector((state) => state.ui.homePage.data);
  const categories = useSelector((state) => state.entities.categories);
  const courses = useSelector((state) => state.entities.courses);

  const [selectedCategory, setStelectedCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [freeVidSec, setFreeVidSec] = useState({
    heading: "",
    heading2: "",
    image: "",
    buttonText: "",
    buttonLink: "",
  });
  const subscriptionPlans = useSelector(
    (state) => state.entities.subscriptionPlans
  );
  const courseMenus = useSelector((state) => state.entities.courseMenus);
  const authToken = useSelector((state) => state.auth.token);

  const filteredCategoriesList = useMemo(() => {
    return categories.list.filter((cat) => {
      const coursesInCategory = courses.list.filter((c) => {
        return cat._id === c.category._id;
      });
      return coursesInCategory.length > 0;
    });
  }, [categories.list, courses.list]);

  useEffect(() => {
    
    if (!selectedCategory && filteredCategoriesList[0]) {
      setCategoryName(filteredCategoriesList[0].name);
      setStelectedCategory(filteredCategoriesList[0]._id);
    }
  }, [filteredCategoriesList]);

  useEffect(() => {
    dispatch(loadHomePageCMS({}));
    dispatch(
      getHomeFreeVidSec({
        onSuccess: (res) => {
          setFreeVidSec(res.data.data);
        },
      })
    );
  }, []);

  const renderCategories = () => {
    return (
      <>
        {(filteredCategoriesList || []).map((cat) => {
          return (
            <li
              className="nav-item"
              role="presentation"
              key={cat._id + "home course"}
            >
              <a
                className={
                  selectedCategory === cat._id ? "nav-link active" : "nav-link"
                }
                onClick={(e) => {
                  e.preventDefault();
                  setStelectedCategory(cat._id);
                  setCategoryName(cat.name);
                }}
                id="marketing-tab"
                data-toggle="tab"
                role="tab"
                aria-controls="marketing"
                aria-selected="false"
              >
                {cat.name}
              </a>
            </li>
          );
        })}
      </>
    );
  };

  const goToSubscription = (plan) => {
    if (!authToken) {
      dispatch(setLoginModalVisibility(true));
      storage.store("GO_TO_SUBSCRIPTION", {
        visible: true,
        category: selectedCategory,
        plan,
      });
      return;
    }
    props.history.push(`/subscription/${selectedCategory}`);
    dispatch(
      setSubscriptionModal({
        visible: true,
        category: selectedCategory,
        plan,
      })
    );
  };

  const renderSubscriptionCards = () => {
    if (!selectedCategory) return null;
    const plansForThisCategory = subscriptionPlans.list.filter(
      (sp) => sp.category._id === selectedCategory
    );

    return (
      <div className="row justify-content-center">
        {plansForThisCategory.map((subscriptinPlan) => {
          return (
            <div className="col-sm-4 mt-3" key={subscriptinPlan._id}>
              <div className="mx-5 mx-md-0">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    goToSubscription(subscriptinPlan);
                  }}
                >
                  <img className="w-100" src={subscriptinPlan.cardImage} />
                </a>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderMenuCards = () => {
    const {
      oLevelLinks,
      oLevelImage,
      //plus
      satPrepLinks,
      satPrepImage,
      //premium
      aLevelLinks,
      aLevelImage,
    } = courseMenus;
    const menus = [
      {
        image: oLevelImage,
        link: oLevelLinks,
      },

      {
        image: satPrepImage,
        link: satPrepLinks,
      },

      {
        image: aLevelImage,
        link: aLevelLinks,
      },
    ];
    return (
      <div className="container mb-5" style={{ marginTop: "20px" }}>
        <div className="row">
          {menus.map((men) => {
            return (
              <div className="col-sm-4 mt-3">
                <div className="mx-5 mx-md-0">
                  <Link to={men.link}>
                    <img src={men.image} className="w-100" />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <meta property="og:title" content={homePageCMS.metaTitle} />
        <meta name="description" content={homePageCMS.metaDescription} />
        <meta name="keywords" content={homePageCMS.metaKeyWords} />
      </Helmet>
      <HomeSlider
        buttonText={homePageCMS.buttonText1}
        buttonLink={homePageCMS.buttonLink1}
      />
      <DragableButton/>
<PopularCourse/>
<WhatsNew/>
<OcCommunity/>
      <Teachers
        heading={homePageCMS.heading2}
        teacherText={homePageCMS.teacherText}
        buttonText={homePageCMS.buttonText2}
        buttonLink={homePageCMS.buttonLink2}
      />
      <ResultSlider/>
<UnbeatablePrice/>
      <HomeDetails/>
      <HomeSlider3/>
      <StudentRating/>

      <FreeTrail/>
      <HappyBtn/>

    </>
  );
};

Home.loadData = ({ store, matchedRoute }) => {
  const { token } = store.getState().auth;
  const { dispatch } = store;
  const promiseArray = [];

  // if (token) {
  promiseArray.push(dispatch(loadHomePageCMS({})));
  // }
  return Promise.all(promiseArray);
};

export default Home;
